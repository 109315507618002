import React from 'react';
import whatsapp from '../../assets/images/whatsapp.png'
import './index.scss';

function WhatsAppIcon() {
  return (
    <div className="whatsapp-icon">
      <a href="https://wa.me/+5492364337038" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="Icono de WhatsApp" width="50" height="50" />
      </a>
    </div>
  );
}

export default WhatsAppIcon;