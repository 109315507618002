import img1 from '../../assets/images/Amados1.jpg';
import img2 from '../../assets/images/Amados2.jpg';
import img3 from '../../assets/images/Amados3.jpg';

const dataImage = [
    img1,
    img2,
    img3
]

const dataText = [
   {
    title: 'Tus muebles a medida',
    subtitle: '¿Buscas darle un toque único a tu hogar? ¡somos la solución perfecta para vos!',
   },
   {
    title: 'Remodelá tu cocina',
    subtitle: 'Hacemos bajomesadas, alacenas, más Completamente a medida, podés elegir el diseño, el color y la combinación perfecta para lograr lo que buscas',
   },
   {
    title: '¿Buscás placares y raks?',
    subtitle: 'Eligí tu diseño, el color y el tipo de madera, y lo hacemos lo soñas',
   }
]

export { dataImage, dataText };