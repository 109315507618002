// src/components/CustomCarousel.js
import React, { useState } from 'react';
import './custom-carousel.scss';
import ContentImage from '../content-img';
import { dataImage, dataText } from './data';

const CustomCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? dataImage.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === dataImage.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className={`carousel color-${currentIndex}`} >
      <button onClick={goToPrevious} className="carousel-button prev">❮</button>
      <div className={`carousel-content`}>
        <ContentImage dataText={dataText[currentIndex]}/>
      </div>
      <button onClick={goToNext} className="carousel-button next">❯</button>
    </div>
  );
};

export default CustomCarousel;
