import React from 'react'

export default function ContentImage(dataText, left) {
  return (
    <div className='content-image'>
      <h2 className='carrousel-title libre-bodoni'>{dataText.dataText.title}</h2>
      <p className='carrousel-subtitle'>{dataText.dataText.subtitle}</p>
      <span className='carrousel-footer'>CONTANOS TU NECESIDAD, Y LO HACEMOS REALIDAD</span>
    </div>
  )
}
