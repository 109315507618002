import React from 'react';

function Footer() {
  return (
    <footer style={{
      backgroundColor: '#f2f2f2',
      padding: '20px',
      textAlign: 'center'
    }}>
      <p>© {new Date().getFullYear()} Mi Sitio Web. Todos los derechos reservados.</p>
      <nav>
        <ul>
          <li><a href="/">Inicio</a></li>
          <li><a href="/about">Acerca de</a></li>
          <li><a href="/contact">Contacto</a></li>
        </ul>
      </nav>
      <div>
        <a href="https://www.facebook.com"><i className="fab fa-facebook"></i></a>
        <a href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
        <a href="https://www.instagram.com"><i className="fab fa-instagram"></i></a>
      </div>
    </footer>
  );
}

export default Footer;