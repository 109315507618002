import React from 'react'
import Carousel from '../components/carrousel';
import Deals from '../components/deals';
import Sections from '../components/sections';

export default function Home() {
  return (
    <>
      <Carousel />
      <Deals />
      <Sections />
    </>
  )
}
